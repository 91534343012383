/*--------------------------------------------------------------
# Variable
--------------------------------------------------------------*/
body.home .information {
  padding: 5px 10px; }
  body.home .information .c-card__title {
    position: relative; }
  body.home .information .c-btn.c-btn--default {
    background: #fff;
    position: absolute;
    right: 10px;
    top: 6px;
    box-sizing: border-box;
    display: inline-block;
    padding: 4px 15px 6px 15px;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 1.3rem;
    border: 2px solid transparent;
    border-color: currentColor; }

body.home .p-band--dusk {
  background: transparent; }

body.home .p-socialLinks .p-socialLinks__items {
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: start;
  justify-content: start;
  -webkit-align-content: space-between;
  align-content: space-between; }
  body.home .p-socialLinks .p-socialLinks__items .c-grid__u {
    width: auto;
    max-width: none;
    min-width: auto;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    width: 20%;
    text-align: center; }
    body.home .p-socialLinks .p-socialLinks__items .c-grid__u .fa {
      font-size: 25px; }
  body.home .p-socialLinks .p-socialLinks__items .p-socialLinks__item {
    height: 50px;
    line-height: 50px; }
    body.home .p-socialLinks .p-socialLinks__items .p-socialLinks__item span {
      display: none; }

body.home .p-band--dusk .c-container {
  padding: 5px; }

body.home .linkWrap.cmp3aBox {
  margin-top: 0;
  position: relative;
  padding: 30px 10px;
  background: transparent url(../img/member-bg.png) no-repeat scroll center top;
  background-size: cover;
  height: auto; }
  body.home .linkWrap.cmp3aBox .mobile3a-ttl {
    margin: 0 auto;
    display: block;
    text-align: center; }
    body.home .linkWrap.cmp3aBox .mobile3a-ttl img {
      width: 90%; }
  body.home .linkWrap.cmp3aBox .cmp3aList {
    padding: 20px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    color: #000;
    position: relative;
    z-index: 10; }
    body.home .linkWrap.cmp3aBox .cmp3aList li {
      font-weight: bold !important; }
    body.home .linkWrap.cmp3aBox .cmp3aList .cmpClosed {
      border: 1px solid #000; }
  body.home .linkWrap.cmp3aBox .cmp3aList:before {
    content: "";
    display: block;
    width: 240px;
    height: 50px;
    background: transparent url(../img/member-ttl02_2.png) no-repeat scroll center top;
    background-size: contain;
    margin: 0 auto 10px auto; }

body.home .member-ticket {
  width: 350px;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

body.home .member-ttl01 {
  text-align: center;
  display: block;
  margin-bottom: 25px; }
  body.home .member-ttl01 img {
    width: 350px;
    margin: 0 auto; }

#smp-mode .event-table th {
  text-align: left; }

#smp-mode .eventCalendar .p-yearNav .p-yearNav__items .p-yearNav__item:first-child,
#smp-mode .eventCalendar .p-yearNav .p-yearNav__items .p-yearNav__item:nth-child(2),
#smp-mode .eventCalendar .p-yearNav .p-yearNav__items .p-yearNav__item:nth-child(3),
#smp-mode .eventCalendar .p-yearNav .p-yearNav__items .p-yearNav__item:nth-child(4) {
  display: none; }

@media screen and (min-width: 980px) {
  .pc {
    display: block; }
  .sp {
    display: none; } }

@media screen and (max-width: 980px) {
  .pc {
    display: none; }
  .sp {
    display: block; } }
#smp-mode .ininbound-block{
    border: 1px solid #333;
    padding: 20px;
    margin: 10px 0 20px 0;    
}
#smp-mode .ininbound-block h4{
    font-family: "TeXGyreAdventor", "futura-pt", "futura", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
    margin: 0 0 20px 0;
    margin-bottom: 10px;
    font-size: 16px;
}
#smp-mode .ininbound-block p{
    margin: 0;
    font-size: 14px;
    font-weight: normal;
}
#smp-mode .ininbound-block a{
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0;
    word-break: break-all;
}